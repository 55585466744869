import { getApiKey } from '@/_helper/envHelper'
const API_BASE_URL = process.env.VUE_APP_WEAVR_API_URL;

async function fetchWithToken(url, options = {}, login = false) {
    const token = localStorage.getItem('token');
    const apiKey = localStorage.getItem('apiKey') ?? getApiKey();
    if (!token && !login) {
        throw new Error('No authentication token found');
    }

    try {
        const headers = {
            ...(options.headers || {}),
            'Content-Type': 'application/json',
            'api-key': apiKey,
        };
        if( !login ) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        options['headers'] = headers;
        const response = await fetch(url, {
            ...options
        });
        if (!response.ok) {
            const error = new Error('An error occurred: ' + response.statusText);
            error.response = response;
            //throw error;
        }
        if(response.status === 204){
            return response;
        }
        return response.json();
    } catch (error) {
        if (error.response) {
            console.error('HTTP Error:', error.message);
        } else {
            console.error('Network Error:', error.message);
        }
        //throw error;
    }
}

export const weavrApiService = {
    async getUserDetails() {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            throw new Error('No UserID found');
        }
        const url = `${API_BASE_URL}/multi/users/${userId}`;
        return fetchWithToken(url);
    },
    async confirmCodeToUnlockCard(cardId, unlockCode) {
        const url = `${API_BASE_URL}/multi/managed_cards/${cardId}/physical/activate`;
        const options = {
            method: 'POST',
            body: JSON.stringify({
                activationCode: unlockCode,
            })
        };
        return fetchWithToken(url, options);
    },
    async unBlockCard(cardId) {
        const url = `${API_BASE_URL}/multi/managed_cards/${cardId}/unblock`;
        const options = {
            method: 'POST',
        };
        return fetchWithToken(url, options);
    },
    async blockCard(cardId) {
        const url =  `${API_BASE_URL}/multi/managed_cards/${cardId}/block`;
        const options = {
            method: 'POST',
        };
        return fetchWithToken(url, options);
    },
    async cardStatement(cardNumber){
        const urlStatments = `${API_BASE_URL}/multi/managed_cards/${cardNumber}/statement`;
        return fetchWithToken(urlStatments, {
            headers: {
                Accept: "string",
            }
        });
    },
    async getCardDetails(cardNumber){
        const url = `${API_BASE_URL}/multi/managed_cards/${cardNumber}`;
        return fetchWithToken(url);
    },
    async getPhysicalCardPin(cardNumber){
        const url = `${API_BASE_URL}/multi/managed_cards/${cardNumber}/physical/pin`;
        return fetchWithToken(url);
    },
    async verifyStepUpOTP(stepUpCode){
        const url = `${API_BASE_URL}/multi/stepup/challenges/otp/SMS/verify`;
        const options = {
            method: 'POST',
            body: JSON.stringify({verificationCode : stepUpCode})
        };
        return fetchWithToken(url, options);
    },
    async loginWithPassword(email, password){
        const url = `${API_BASE_URL}/multi/login_with_password`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
                {
                    email: email,
                    password: { value: password },
                }
            ),
        };
        return fetchWithToken(url, options, true);
    },
    async getUserStatus(){
        const url = `${API_BASE_URL}/multi/authentication_factors`;
        return fetchWithToken(url);
    },
    async setpUp(){
        const url = `${API_BASE_URL}/multi/stepup/challenges/otp/SMS`;
        const options = {
            method: 'POST',
        };
        return fetchWithToken(url, options);
    },
    async inviteConsume(userId, nonce, password) {
        const url = `${API_BASE_URL}/multi/users/${userId}/invite/consume`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
                {
                    inviteCode: nonce,
                    password: { value: password },
                }
            ),
        };
        return fetchWithToken(url, options, true);
    },
    async enrolUserDevice(){
        const url = `${API_BASE_URL}/multi/authentication_factors/otp/SMS`;
        const options = {
            method: 'POST',
        };
        return fetchWithToken(url, options);
    },
    async enrolUserDeviceVerify(verificationCode){
        const url = `${API_BASE_URL}/multi/authentication_factors/otp/SMS/verify`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
                {
                    verificationCode: verificationCode
                }
            ),
        };
        return fetchWithToken(url, options);
    },

    async updateUserPhoneNumber(countryCode, phoneNum){
        const userId = localStorage.getItem('userId');
        const url = `${API_BASE_URL}/multi/users/${userId}`;
        const options = {
            method: 'PATCH',
            body: JSON.stringify(
                {
                    mobile: {
                        countryCode: countryCode,
                        number: phoneNum,
                    }
                }
            ),
        };
        return fetchWithToken(url, options);
    },

    async initNewPassword(email){
        const url = `${API_BASE_URL}/multi/passwords/lost_password/start`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
                {
                    email: email,
                }
            ),
        };
        return fetchWithToken(url, options, true);
    },

    async setNewPassword(email, nonce, password) {
        const url = `${API_BASE_URL}/multi/passwords/lost_password/resume`;
        const options = {
            method: 'POST',
            body: JSON.stringify(
                {
                    nonce: nonce,
                    email: email,
                    newPassword: { value: password },
                }
            ),
        };
        return fetchWithToken(url, options, true);
    },


};
