<template>
  <div id="header">
    <Logo />
    <UserAccountMenu />
  </div>
</template>
<script>
import UserAccountMenu from "@/components/UserAccountMenu.vue";
import Logo from "@/components/Logo.vue";

export default {
  components: { UserAccountMenu, Logo },
};
</script>
<style scoped>
#header {
  position: relative;
  min-height: 65px;
}
</style>

