import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import StepUp from '../views/admin/StepUp.vue'
import Dashboard from '../views/admin/Dashboard.vue'
import Operations from '../views/admin/Operations.vue'
import AdminLayout from '../views/admin/AdminLayout.vue'
import ResetPassword from '../views/ResetPassword.vue'
import {
  authGuard
} from '@/_helper/auth-guard'
import StepUpRegister from '../views/admin/StepUpRegister.vue'
import StepUpPhoneNumber from "@/views/admin/StepUpPhoneNumber.vue";

const routes = [{
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/invite/consume',
    name: 'Register',
    component: Register
  },
  {
    path: '/set-password/:token/:email',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: AdminLayout,
    children: [
      {
        path: 'Dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'Operations',
        name: 'Operations',
        component: Operations
      },
      {
        path: 'StepUp',
        name: 'StepUp',
        component: StepUp
      },
      {
        path: 'StepUpPhoneNumber',
        name: 'StepUpPhoneNumber',
        component: StepUpPhoneNumber
      },
      {
        path: 'StepUpRegister',
        name: 'StepUpRegister',
        component: StepUpRegister
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/admin/Dashboard'
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched[0].name == 'Admin') {
    authGuard()
  }
  next()
})

export default router