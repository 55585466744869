<template>
  <router-view />
</template>
<script>
export default {
  mounted() {
    const url = window.location.href;
    if (url.includes(process.env.VUE_APP_WEAVR_CE_APP_URL)) {
      localStorage.setItem("apiKey", process.env.VUE_APP_CE_API_KEY);
    } else{
      localStorage.setItem("apiKey", process.env.VUE_APP_CE_API_KEY);
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Montserrat", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 12px;
  background: url("./assets/img/verso.png");
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
h3 {
  font-size: 20px;
  font-weight: bold;
}
// forms

.form-group {
  font-size: 16px;
  position: relative;
  padding: 10px;
  label {
    display: block;
    padding: 3px 0;
    text-align: left;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 700;
  }
  input {
    display: block;
    border: none;
    border-bottom: 1px solid black;
    padding: 10px 0;
    font-size: 12px;
    width: 100%;
  }
  button {
    background: black;
    text-transform: uppercase;
    color: white;
    padding: 10px 30px;
    border-radius: 20px;
    border: 1px solid white;
    &:hover {
      border: 1px solid black;
      background-color: white;
      color: black;
    }
  }
  .error-message {
    text-align: left;
    color: red;
    font-size: 12px;
    padding-top: 4px;
  }
  .forgotPassword {
    
    font-size: 12px;
    text-align: left;
    color: #000;

  }
  .showHidePassword {
    position: absolute;
    right: 14px;
    top: 42px;
    width: 16px;
    height: 18px;
  }
}

.form-group-btn {
  text-align: center;
  button {
    background: black;
    text-transform: uppercase;
    color: white;
    padding: 10px 30px;
    border-radius: 20px;
    border: 1px solid white;
    margin-top: 20px;
    margin-bottom: 10px;
    &:hover {
      border: 1px solid black;
      background-color: white;
      color: black;
    }
  }
}

/* Styles pour la modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .second-step,
  .first-step {
    font-size: 15px;
    display: block;
    &.current {
      display: block;
    }
  }
  p {
    line-height: 15px;
  }
  .btn-modal {
    background: black;
    text-transform: uppercase;
    color: white;
    padding: 10px 30px;
    border-radius: 20px;
    border: 1px solid white;
    display: inline-block;
    width: auto;
    cursor: pointer;
    &:hover {
      border: 1px solid black;
      background-color: white;
      color: black;
    }
  }
}

.modal-content {
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  h3 {
    font-size: 20px;
  }
  .close {
    display: inline-block;
    width: 18px;
    position: absolute;
    right: 20px;
    top: 20px;
    height: 19px;
    cursor: pointer;
  }
}

.btn-inscription {
  background: black;
  text-transform: uppercase;
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  border: 1px solid white !important;
  display: inline-block;
  width: auto !important;
  margin: 0 auto;
  padding-left: 20px !important;
  padding-right: 20px !important;
  cursor: pointer;
  &:hover {
    border: 1px solid black !important;
    background-color: white;
    color: black;
  }

  &:disabled {
    background: #c8c8c8;
    &:hover {
      color: #fff;
      border: 1px solid white !important;
    }
  }
}

//tabs

.tabset {
  text-align: left;
}

.tabset > input[type="radio"] {
  position: absolute;
  left: -200vw;
}
.tabset .tab-panel {
  display: none;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}

.tabset > label {
  position: relative;
  display: inline-block;
  padding: 5px 15px;
  cursor: pointer;
  font-weight: 600;
  color: #999;
}

input:focus-visible + label {
  outline: 2px solid rgba(0, 102, 204, 1);
  border-radius: 3px;
}

.tabset > label:hover,
.tabset > input:focus + label,
.tabset > input:checked + label {
  color: #666;
}

.tabset > input:checked + label {
  border-color: #ccc;
  border-bottom: 1px solid #000;
  color: #000;
}

.tab-panel {
  padding: 0;
  //border-top: 1px solid #ccc;
}
</style>
