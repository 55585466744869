<template>
  <div class="register-page">
    <h3>Inscription</h3>
    <form>
      <div class="form-group">
        <label for="email">Email<sup>*</sup> : </label>
        <input type="email" placeholder="Email" v-model="email" disabled/>
        <br />
      </div>
      <div class="form-group">
        <label>Mot de passe<sup>*</sup> : </label>
        <input
          :type="showPassword ? 'text' : 'password'" 
          id="password"
          v-model="password"
          @input="validatePassword"
        />
        <a href="#" @click="toggleShowPassword " class="showHidePassword" :class="{ 'visible': showPassword }">
          <img v-if="!showPassword" src="../assets/img/ico/visibility.png">
          <img v-if="showPassword" src="../assets/img/ico/visibility_off.png">
        </a>
        <br />
      </div>
      <p style="text-align: left; font-size: 11px">
        5 régles à remplir : Votre mot de passe doit contenir au moins 8
        caractères avec au minimum 1 lettre minuscule, 1 lettre majuscule, 1
        chiffre et 1 caractère spécial.
      </p>

      <div class="form-group">
        <label>Confirmer mon mot de passe<sup>*</sup> : </label>
        <input
          :type="showPasswordConfirmataion ? 'text' : 'password'" 
          id="confirm-password"
          v-model="confirmPassword"
          @input="validatePassword"
        />
        <a href="#" @click="toggleShowPasswordConfirmation " class="showHidePassword" :class="{ 'visible': showPasswordConfirmataion }">
          <img v-if="!showPasswordConfirmataion" src="../assets/img/ico/visibility.png">
          <img v-if="showPasswordConfirmataion" src="../assets/img/ico/visibility_off.png">
        </a>
        <br />
      </div>
      <p :class="{ valid: passwordValid }">Mot de passe valide :</p>
      <ol style="text-align: left">
        <li :class="{ valid: eightCharactersValid }">Au moins 8 caractères</li>
        <li :class="{ valid: lowercaseValid }">Au moins 1 lettre minuscule</li>
        <li :class="{ valid: uppercaseValid }">Au moins 1 lettre majuscule</li>
        <li :class="{ valid: digitValid }">Au moins 1 chiffre</li>
        <li :class="{ valid: specialCharValid }">
          Au moins 1 caractère spécial
        </li>
        <li :class="{ valid: passwordMatch }">
          Les mots de passe correspondent
        </li>
      </ol>
      <div class="form-group">
        <input
          id="btn"
          @click="ConfirmRegister"
          class="btn-inscription"
          type="button"
          value="Valider mon compte"
          :disabled="!inscriptionReussie"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { weavrApiService } from "../_services/weavrApiService";
export default {
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      passwordValid: false,
      eightCharactersValid: false,
      lowercaseValid: false,
      uppercaseValid: false,
      digitValid: false,
      specialCharValid: false,
      passwordMatch: false,
      inscriptionReussie: false,
      identity_id: "",
      success: false,
      userId: "",
      apiKey: localStorage.getItem("apiKey"),
      data: null,
      showPassword: false,
      showPasswordConfirmataion: false
    };
  },
  methods: {
    validatePassword() {
      const password = this.password;
      const confirmPassword = this.confirmPassword;

      // Validation de la longueur du mot de passe
      this.eightCharactersValid = password.length >= 8;

      // Validation des critères spécifiques
      this.lowercaseValid = /[a-z]/.test(password);
      this.uppercaseValid = /[A-Z]/.test(password);
      this.digitValid = /\d/.test(password);
      this.specialCharValid = /[!@#$%^&*]/.test(password);

      // Vérification si tous les critères sont valides
      this.passwordValid =
        this.eightCharactersValid &&
        this.lowercaseValid &&
        this.uppercaseValid &&
        this.digitValid &&
        this.specialCharValid;

      this.passwordMatch = password === confirmPassword;

      this.inscriptionReussie =
        this.email && this.passwordValid && this.passwordMatch;

      if (
        this.email &&
        this.passwordValid &&
        this.email &&
        this.passwordMatch
      ) {
        this.inscriptionReussie = true;
      }
    },
    ConfirmRegister() {
      if (
        this.email &&
        this.passwordValid &&
        this.email &&
        this.passwordMatch
      ) {
        this.postData();
      }
    },

    async postData() {
      try {
        this.data = await weavrApiService.inviteConsume(this.userId, this.nonce, this.password);
        this.$router.push("/");
      } catch (error) {
        console.error(error);
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },toggleShowPasswordConfirmation() {
      this.showPasswordConfirmataion = !this.showPasswordConfirmataion;
    },
  },
  beforeCreate() {
    this.apiKey = "";
  },

  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.email = urlParams.get("email");
    this.nonce = urlParams.get("nonce");
    this.userId = urlParams.get("user_id");
    localStorage.setItem("userId", this.userId);
    const identityId = urlParams.get("identity_id");
    if (identityId == process.env.VUE_APP_CE_IDENTITY_ID) {
      localStorage.setItem("apiKey", process.env.VUE_APP_CE_API_KEY);
    }
    if (identityId == process.env.VUE_APP_GF_IDENTITY_ID) {
      localStorage.setItem("apiKey", process.env.VUE_APP_GF_API_KEY);
    }
  },
};
</script>

<style scoped lang="scss">
.register-page {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #bababa;
  margin: 0 auto;

  max-width: 350px;
  width: 100%;

  p {
    text-align: left;
  }
  .valid {
    color: green;
    font-weight: bold;
  }
  .invalid {
    color: red;
  }
}

@media only screen and (max-width: 600px) {
  .register-page {
    border: none;
    width: auto;
  }
}
</style>
