<template>
  <div class="wrapper">
    <div>
      <router-view />
    </div>
  </div>
</template>
<script>
import UserAccountMenu from "@/components/UserAccountMenu.vue";
import Logo from "../../components/Logo.vue";
export default {
  components: { Logo, UserAccountMenu },
  methods: {},
};
</script>

<style scoped lang="scss">
.wrapper {
  max-width: 1000px;
  margin: 0 auto;
  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .user-account {
      .btn-logout {
        text-transform: uppercase;
        color: #fff;
        padding: 10px 30px;
        border-radius: 20px;
        border: 1px solid #fff;
        background: #000;
        cursor: pointer;
        &:hover {
          border: 1px solid black;
          background-color: white;
          color: black;
        }
      }
    }
  }
}
</style>



