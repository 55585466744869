<template>
  <br /><br /><br />
  <div class="stepUp">
    <h3 class="text-center mb-4">Vérifier votre numéro de téléphone</h3>
    <form @submit.prevent="enrolUserDeviceVerify">
      <div class="form-group">
        <p><img src="@/assets/stepUp.png" /></p>
        <p>
          Un code vient de vous être envoyé par SMS. Entrer ce code à
          <b>6 chiffres </b> ci-dessous pour valider votre numéro.
        </p>
        <br />
        <input
          v-model="verificationCode"
          type="text"
          placeholder="Entrez le code de vérification"
        />
        <br /><br />
        <button type="submit">VALIDER MON NUMÉRO</button>
      </div>
    </form>
  </div>
</template>

<script>
import { weavrApiService } from "@/_services/weavrApiService";
import { ref } from "vue";
import { useRouter } from "vue-router";
import router from "@/router";

export default {
  setup() {
    const verificationCode = ref("");
    const router = useRouter();

    const enrolUserDeviceVerify = async () => {
      try {
        const response = await weavrApiService.enrolUserDeviceVerify(verificationCode.value)
        if(response.status === 204){
          router.push('/admin/stepUp');
        }
      } catch (error) {
        console.error("Une erreur s'est produite:", error);
      }
    };

    return {
      verificationCode,
      enrolUserDeviceVerify,
    };
  },
  async beforeMount() {
    try {
      const response = weavrApiService.enrolUserDevice();
      response.then( (data) => {
        if (data.errorCode === 'MOBILE_NUMBER_NOT_AVAILABLE') {
          router.push('/admin/StepUpPhoneNumber');
        }
      });
    } catch (error) {
      console.error("Une erreur s'est produite:", error);
    }
  },
};
</script>
<style scoped>
.stepUp {
  max-width: 350px;
  margin: auto;
  border: 1px solid #bababa;
  border-radius: 20px;
  padding: 20px;
}
</style>



