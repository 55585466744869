import router from '@/router'

export function getApiKey() {
    return isUrlGifteo() ? process.env.VUE_APP_GF_API_KEY : process.env.VUE_APP_CE_API_KEY;
}
export function getUIKey() {
    return isUrlGifteo() ? process.env.VUE_APP_GF_UI_KEY : process.env.VUE_APP_CE_UI_KEY;
}
export function getGifteoIndentityId() {
    return process.env.VUE_APP_GF_IDENTITY_ID;
}
export function getCeIndentityId() {
    return process.env.VUE_APP_CE_IDENTITY_ID;
}

function isUrlGifteo() {
    const urlPath = window.location.pathname;
    return urlPath.includes(urlPath.includes(process.env.VUE_APP_WEAVR_GF_APP_URL));
}