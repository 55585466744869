<template>
  <div class="user-menu">
    <div
      class="Unsername-link"
      @click="toggleUserMenu"
      :class="{ active: isUserMenuVisible }"
    >
      {{ nameOnCard }}
    </div>
    <ul v-if="isUserMenuVisible">
      <li>
        <a to="/admin/Dashboard">
          Mon compte
          <img src="../../src/assets/img/ico/user.svg" alt="Mon compte" />
        </a>
      </li>
      <li>
        <a to="/" @click="logout">
          Se deconnecter
          <img src="../../src/assets/img/ico/logout.svg" alt="Se déconnecter" />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { weavrApiService } from "../_services/weavrApiService";

export default {
  data() {
    return {
      isUserMenuVisible: false,
      nameOnCard: "",
    };
  },
  mounted() {
    this.getUserDetails();
  },
  methods: {
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    toggleUserMenu() {
      this.isUserMenuVisible = !this.isUserMenuVisible;
    },
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("userId", "");
      localStorage.setItem("userName", "");
      localStorage.setItem("apiKey", "");
      localStorage.setItem("cardType", "");
      
      localStorage.setItem("firstLoad", false);
      this.$router.push("/");
    },
    async getUserDetails() {
      const data = await weavrApiService.getUserDetails();
      this.nameOnCard = `${data.surname} ${data.name}`;
    },
  },
};
</script>
<style scoped lang="scss">
.user-menu {
  text-align: right;
  position: absolute;
  top: 20px;
  right: 20px;
  max-width: 200px;
  .Unsername-link {
    padding-right: 25px;
    cursor: pointer;
    &:after {
      content: "▼";
      position: absolute;
      right: 0.5em;
      top: 7px;
      transform: translateY(-50%);
    }
    &.active {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding-top: 10px;
    position: absolute;
    z-index: 2;
    background: none;
    min-width: 135px;
    right: -10px;
    top: 20px;
    // box-shadow: -3px 2px 12px 1px rgba(0,0,0,0.36);
    padding: 15px;
    background-color: #fafafa;

    li {
      display: block;
      margin: 0;
      text-align: right;
      a {
        display: block;
        cursor: pointer;
        position: relative;
        line-height: 30px;
        padding-right: 25px;
        img {
          position: absolute;
          right: 0;
          width: 15px;
          top: 7px;
        }
      }
    }
  }
}
</style>
