<template>
  <div class="register-page">
    <h3>Nouveau mot de passe</h3>
    <form>
      <div class="form-group">
        <label>Mot de passe<sup>*</sup> : </label>
        <input
          
        
        
        :type="showPassword ? 'text' : 'password'" 
        id="password"
          v-model="password"
          @input="validatePassword"
        />
        <a href="#" @click="toggleShowPassword " class="showHidePassword" :class="{ 'visible': showPassword }">
          <img v-if="!showPassword" src="../assets/img/ico/visibility.png">
          <img v-if="showPassword" src="../assets/img/ico/visibility_off.png">
        </a>
        <br />
      </div>
      <p style="text-align: left; font-size: 11px">
        5 régles à remplis : Votre mot de passe doit contenir au moins 8
        caractères avec au minimum 1 lettre minuscule, 1 lettre majuscule, 1
        chiffre et 1 caractère spécial.
      </p>

      <div class="form-group">
        <label>Confirmer mon mot de passe<sup>*</sup> : </label>
        <input
          :type="showPasswordConfirmataion ? 'text' : 'password'" 
          id="confirm-password"
          v-model="confirmPassword"
          @input="validatePassword"
        />
        

        <a href="#" @click="toggleShowPasswordConfirmation " class="showHidePassword" :class="{ 'visible': showPasswordConfirmataion }">
          <img v-if="!showPasswordConfirmataion" src="../assets/img/ico/visibility.png">
          <img v-if="showPasswordConfirmataion" src="../assets/img/ico/visibility_off.png">
        </a>
        <br />
      </div>
      <p :class="{ valid: passwordValid }">Mot de passe valide :</p>
      <ol style="text-align: left">
        <li :class="{ valid: eightCharactersValid }">Au moins 8 caractères</li>
        <li :class="{ valid: lowercaseValid }">Au moins 1 lettre minuscule</li>
        <li :class="{ valid: uppercaseValid }">Au moins 1 lettre majuscule</li>
        <li :class="{ valid: digitValid }">Au moins 1 chiffre</li>
        <li :class="{ valid: specialCharValid }">
          Au moins 1 caractère spécial
        </li>
        <li :class="{ valid: passwordMatch }">
          Les mots de passe correspondent
        </li>
      </ol>
      <div class="form-group">
        <input
          id="btn"
          @click="confirmSetPassword"
          class="btn-inscription"
          type="button"
          value="Modifier"
          :disabled="!inscriptionReussie"
        />
      </div>
    </form>
  </div>


  <div class="modal" v-if="newPasswordSetModal">
    
    <div class="modal-content">
      <span class="close" @click="closeModal">
          <img src="../assets/img/ico/cross.png" />
        </span>
      <h3>Nouveau mot de passe</h3>
      <p>Votre nouveau mot de passe est validé</p>
      <br />
      <input
        type="button"
        class="btn-modal"
        value="Me connecter"
        @click="closeModal"
      />
    </div>
  </div>
</template>

<script>
import { weavrApiService } from "../_services/weavrApiService";
export default {
  data() {
    return {
      
      password: "",
      confirmPassword: "",
      passwordValid: false,
      eightCharactersValid: false,
      lowercaseValid: false,
      uppercaseValid: false,
      digitValid: false,
      specialCharValid: false,
      passwordMatch: false,
      inscriptionReussie: false,
      data: null,
      email: this.$route.params.email,
      nonce : this.$route.params.token,
      showPassword: false,
      showPasswordConfirmataion: false,
      newPasswordSetModal: false, 
    };
  },
  methods: {
    validatePassword() {
      const password = this.password;
      const confirmPassword = this.confirmPassword;

      // Validation de la longueur du mot de passe
      this.eightCharactersValid = password.length >= 8;

      // Validation des critères spécifiques
      this.lowercaseValid = /[a-z]/.test(password);
      this.uppercaseValid = /[A-Z]/.test(password);
      this.digitValid = /\d/.test(password);
      this.specialCharValid = /[!@#$%^&*]/.test(password);

      // Vérification si tous les critères sont valides
      this.passwordValid =
        this.eightCharactersValid &&
        this.lowercaseValid &&
        this.uppercaseValid &&
        this.digitValid &&
        this.specialCharValid;

      this.passwordMatch = password === confirmPassword;

      this.inscriptionReussie = this.passwordValid && this.passwordMatch;

      if (
        this.email &&
        this.passwordValid &&
        this.email &&
        this.passwordMatch
      ) {
        this.inscriptionReussie = true;
      }
    },
    confirmSetPassword() {
      if (
        this.passwordValid &&
        this.passwordMatch
      ) {
        this.postData();
      }
    },

    async postData() {
      try {
        this.data = await weavrApiService.setNewPassword(this.email, this.nonce, this.password);

        if( this.data.token) {
          this.newPasswordSetModal = true;
        }
        
        
      } catch (error) {
        console.error(error);
      }
    },

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },toggleShowPasswordConfirmation() {
      this.showPasswordConfirmataion = !this.showPasswordConfirmataion;
    },

    closeModal() {
      this.$router.push("/");
    }
  },


};
</script>

<style scoped lang="scss">
.register-page {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #bababa;
  margin: 0 auto;

  max-width: 350px;
  width: 100%;

  p {
    text-align: left;
  }
  .valid {
    color: green;
    font-weight: bold;
  }
  .invalid {
    color: red;
  }
}

@media only screen and (max-width: 600px) {
  .register-page {
    border: none;
    width: auto;
  }
}
</style>
