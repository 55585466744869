<template>
  <Header />
  <p class="backLink" @click="goBack">
    <img src="../../assets/img/ico/back.png" />
    <span>Retour </span>
  </p>
  <h1>Informations de votre carte</h1>
  <div class="dashbord">
    <div class="operations">
      <div class="balance">
        <div class="balance-title">Solde</div>
        <div class="balance-value">
          <b>{{ balance }}€</b>
        </div>
      </div>

      <div class="cardDetails">
        <div class="card" :class="{ hover: isHovered }">
          <div  class="showCode" :class="{ hideCode: isHovered }"  @click="toggleCodeClass">
            <img  :src="imageUrl"/>
          </div>
          <div class="card-inner">
            <div class="card-front">
              <img
                src="../../assets/img/pictos/mastercard-2-logo.svg"
                class="master-card"
              />

              <div class="card-holder">{{ nameOnCard }}</div>
              <div class="card-number">
                <span id="cardNumber" />
              </div>
              <div class="card-valid">{{ expiryMmyy }}</div>
              <img class="faceA" src="../../assets/img/carte.png" />
            </div>
            <div class="card-back">
              <img class="faceB" src="../../assets/img/verso.png" />


              <div v-if="cardType === 'PHYSICAL'" class="pin">CODE PIN: <span id="pin"></span>
              </div>
              <div class="card-seccode">
                <b> CVV: <span id="cvv" /></b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
        <div class="showCodeTextMode" :class="{ hideCode: isHovered }"  @click="toggleCodeClass">
          <img v-if="!isHovered" src="../../assets/img/ico/visibility.png">
          <img v-if="isHovered" src="../../assets/img/ico/visibility_off.png">
          <span class="showCodeTxt" >
            Voir le <span v-if="!isHovered">verso</span> <span v-if="isHovered">recto</span> de la carte
          </span>
        </div>
      <br />
      <input
        id="btn"
        @click="triggerHover"
        class="btn-inscription"
        type="button"
        value="Voir le code PIN"
        v-if="cardType === 'PHYSICAL'" 
      />
      &nbsp;&nbsp;
    
      
      <br />

      <div class="tabs">
        <div class="tabset">
          <!-- Tab 1 -->
          <input
            type="radio"
            name="tabset"
            id="tab1"
            aria-controls="marzen"
            checked
          />
          <label for="tab1">Operations</label>
          <div class="tab-panels">
            <section id="operations-wrapper" class="tab-panel">
              <ul class="operations-list">
                <li
                  v-for="(statement, index) in filteredStatements"
                  :key="index"
                >
                  <div class="operation-picto"></div>
                  <div class="operation-description">
                    <div class="operation-title">
                      {{ getStatementName(statement) }}
                    </div>
                    <div class="operation-details">
                      <span>
                        {{ formatDate(statement.processedTimestamp) }}
                      </span>
                      <span
                        class="status"
                        v-if="
                          statement.transactionId.type !==
                            'AUTHORISATION_DECLINE' &&
                          statement.entryState === 'COMPLETED'
                        "
                      >
                        - Traitement terminé
                      </span>
                      <span
                        class="declined"
                        v-if="
                          statement.transactionId.type ===
                          'AUTHORISATION_DECLINE'
                        "
                      >
                        - Operation déclinée
                      </span>
                    </div>
                  </div>
                  <div class="operation-amount">
                    <div
                      v-if="
                        statement.transactionId.type == 'AUTHORISATION_DECLINE'
                      "
                    >
                      <del>
                        <b
                          >{{
                            (statement.transactionAmount.amount / 100).toFixed(
                              2
                            )
                          }}
                          €</b
                        >
                      </del>
                    </div>
                    <div v-else>
                      <span v-if="statement.transactionId.type == 'TRANSFER'"
                        >+</span
                      >
                      <b
                        >{{
                          (statement.transactionAmount.amount / 100).toFixed(2)
                        }}
                        €</b
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" v-if="showModal">
    <div class="modal-content">
      <h3>Votre code PIN</h3>
      <br /><br />
      <img src="../../assets/img/pictos/pin.png" />
      <br /><br /><br />
      <p>Card PIN:</p>
      <br /><br />
      <div>
        <span id="pin" />
      </div>
      <input
        type="button"
        class="btn-modal"
        value="Fermer"
        @click="closeModal"
      />
    </div>
  </div>
</template>
<script>
import { weavrApiService } from "../../_services/weavrApiService";
import { ceApiService } from "../../_services/ceApiService";
import Header from "@/components/Header.vue";

export default {
  data() {
    return {
      cards: [],
      nameOnCard: "",
      expiryMmyy: "",
      balance: "",
      statments: [],
      isUserMenuVisible: false,
      showModal: false,
      token: localStorage.getItem("token"),
      apiKey: localStorage.getItem("apiKey"),
      userId: localStorage.getItem("userId"),
      cardType: localStorage.getItem("cardType"),
      isHovered: false,
      showCode: false,
      

      imageUrl:       "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAANCAYAAABPeYUaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADtSURBVHgBfVIBEcIwDOzuEDAJkzAJlTAJc8AcbA6og9XBQEFxgITiAByE5PgeIZTl7q/rJ/mkyZwzRkSecWJk+tiDkRij2zMOaJGskwJjYUQlKudQE+hU0AJB6WiF2Ky4EjdbkYzqHvcjAkVgg1/Qwh/hH4rADGI0oqvpVIpM6uk3cK3DJZkEKl0pXroKJs4XkYCkXlWRCosZelDfvraVDHTgirBsa0S3nepIhr9ZoR7Vc5kNAjPE9HySPvfWLNUmvNmrrW30bWdXM9W+tqSe8sMfrEjTNJGPSO9/ogd9dzt2+OdgsScfV0NfavcXu6HOOeaTtUkAAAAASUVORK5CYII=",
      imageShowCode:  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAYAAAACsSQRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADmSURBVHgBpZIBEcMgDEXp3QQgAQmVUAmVMAl1QCXUQetgEpBQCUiohCwcP7eMQbfecvePweDlJ6khIsfy5p9gwJ1yrOrMsyK94mCFdPdnEK8968FaWDNWgaZ1rEEcspWOFjjwLMsaFMyXkB2QpeEoQhbnG+6Nun6Sehs9EqcT9lYltuIiFA+oAgrFfsSdyagSepXlKEEobVKQFf87eRQhpxpKNUdFC2Z92CN7lGZRHm2sOJL+bKYxZnkUknXKIx2w1yBrzgLT2ekzjlpp32BWOXGqxGugE6dvoJu5GF3Xpa81/XRy9gTJTf56/3nOmAAAAABJRU5ErkJggg==",
      imageHideCode : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAANCAYAAABPeYUaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADtSURBVHgBfVIBEcIwDOzuEDAJkzAJlTAJc8AcbA6og9XBQEFxgITiAByE5PgeIZTl7q/rJ/mkyZwzRkSecWJk+tiDkRij2zMOaJGskwJjYUQlKudQE+hU0AJB6WiF2Ky4EjdbkYzqHvcjAkVgg1/Qwh/hH4rADGI0oqvpVIpM6uk3cK3DJZkEKl0pXroKJs4XkYCkXlWRCosZelDfvraVDHTgirBsa0S3nepIhr9ZoR7Vc5kNAjPE9HySPvfWLNUmvNmrrW30bWdXM9W+tqSe8sMfrEjTNJGPSO9/ogd9dzt2+OdgsScfV0NfavcXu6HOOeaTtUkAAAAASUVORK5CYII=",
      
      validTransactionTypes: [
        "TRANSFER",
        "AUTHORISATION_DECLINE",
        "SETTLEMENT",
      ],
    };
  },
  async mounted() {
      ceApiService.getCurrentUserCardDetails()
      .then(async (response) => {
        let cardNumber = response.data["hydra:member"][0].identifiant;
        const token = localStorage.getItem("token");
        try {
          const cardDetails = await weavrApiService.getCardDetails(cardNumber);
          this.nameOnCard = cardDetails.nameOnCard;
          this.expiryMmyy = cardDetails.expiryMmyy.replace(
            /(\d{2})(\d{2})/,
            "$1/$2"
          );
          this.balance = cardDetails.balances.actualBalance / 100;
          const theCvvToken = cardDetails.cvv.value;
          const cardNumberToDisplay = cardDetails.cardNumber.value;

          const cardPinDetails = await weavrApiService.getPhysicalCardPin(
            cardNumber
          );
          // get pin code
          window.OpcUxSecureClient.associate(
            `Bearer ${token}`,
            function () {
              var span = window.OpcUxSecureClient.span(
                "cardPin",
                cardPinDetails.pin.value,
                {
                  style: {
                    fontSize: "12px",
                    color: "#021318",
                    height: "20px",
                  },
                }
              );
              span.mount(document.getElementById("pin"));
            },
            function (e) {
              console.error("associate failed " + e);
            }
          );
          // get card number
          window.OpcUxSecureClient.associate(
            `Bearer ${token}`,
            function () {
              var span = window.OpcUxSecureClient.span(
                "cardNumber",
                cardNumberToDisplay,
                {
                  style: {
                    fontSize: "16px",
                    color: "#FFFFFF",
                    height: "20px",
                  },
                }
              );
              span.mount(document.getElementById("cardNumber"));
            },
            function (e) {
              console.error("associate failed " + e);
            }
          );
          // get CVV code
          window.OpcUxSecureClient.associate(
            `Bearer ${token}`,
            function () {
              var span = window.OpcUxSecureClient.span("cvv", theCvvToken);
              span.mount(document.getElementById("cvv"));
            },
            function (e) {
              console.error("associate failed " + e);
            }
          );
        } catch (error) {
          console.error("Error:", error);
        }

        try {
          const statements = await weavrApiService.cardStatement(cardNumber);
          console.log(statements);
          this.statments = statements.entry;
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.error(error);
        localStorage.setItem("token", "");
        this.$router.push("/");
      });



      
  },
  computed: {
    filteredStatements() {
      return this.statments.filter((item) =>
        this.validTransactionTypes.includes(item.transactionId.type)
      );
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    triggerHover() {
      this.isHovered = true;
      

      setTimeout(() => {
        this.isHovered = false;
        this.imageUrl = this.imageHideCode;
        
      }, 5000);

      if (this.isHovered) {
        this.imageUrl = this.imageShowCode; 
      } else {
        this.imageUrl = this.imageHideCode;
      }
    },
    
    toggleCodeClass() {
      this.isHovered = !this.isHovered;
      this.showCode = !this.showCode;

      if (this.isHovered) {
        this.imageUrl = this.imageShowCode; 
      } else {
        this.imageUrl = this.imageHideCode;
      }
    },
    formatDate(ts) {
      const date = new Date(ts);
      // Format the date as needed
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    },
    getStatementName(statement) {
      if ("merchantName" in statement.additionalFields) {
        return statement.additionalFields.merchantName;
      } else if ("sourceInstrumentFriendlyName" in statement.additionalFields) {
        return statement.additionalFields.sourceInstrumentFriendlyName;
      }

      return "";
    },
    goBack() {
        this.$router.go(-1)
    },



    
    
  },
  components: { Header },
};
</script>

<style scoped lang="scss">
.card {
  width: 309px;
  height: 202px;
  //border-radius: 10px;
  perspective: 1000px;
  margin: auto;
  margin-top: 30px;
  .showCode {
    position: absolute;
    top : -10px;
    right: -10px;
    background:  #11A783;
    border-radius: 15px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    z-index: 3;
    padding: 3px;
    cursor: pointer;
    img{
      width: 17px;
      margin: auto;
      position: relative;
      top: 4px;

    }
  }

  /*&:hover .card-inner {
    transform: rotateY(180deg);
  }*/

  &.hover .card-inner {
    transform: rotateY(180deg);
  }

  .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: transform 600ms ease;
    transform-style: preserve-3d;
    box-shadow: 0 0 25px 2px rgba(0, 0, 0, 0.2);

    .card-front,
    .card-back {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      backface-visibility: hidden;
      background: linear-gradient(321.03deg, #01adef 0%, #0860bf 91.45%);
      background: url(../../assets/img/verso.png) no-repeat 0 0;
      background-size: 309px 223px;
    }

    .card-front {
      overflow: hidden;
      position: relative;
      transition: transform 300ms ease-in-out;
      z-index: 1;
      
      .master-card {
        width: 57px;
        position: absolute;
        bottom: 10px;
        right: 22px;
        z-index: 2;
      }
      .faceA {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
      }
      .card-holder {
        position: absolute;
        left: 25px;
        bottom: 10px;
        color: white;
        font-size: 14px;
        letter-spacing: 0.2em;
        filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
        z-index: 2;
      }
      .card-number {
        position: absolute;
        left: 25px;
        bottom: 115px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.2em;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
        z-index: 2;
        #cardNumber {
          display: block;
          width: 100%;
          width: 160px;
          height: 20px;
        }
      }

      .card-valid {
        position: absolute;
        left: 25px;
        bottom: 30px;
        color: white;
        font-size: 14px;
        letter-spacing: 0.2em;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
        z-index: 2;
      }
    }
    .card-back {
      transform: rotateY(180deg);
      z-index: 2;
      .faceB {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
      }
      .card-seccode {
        position: absolute;
        top: 125px;
        left: 245px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 17px;
        color: #021318;
        background-color: #eeecec;
        text-align: center;
        font-size: 11px;
        z-index: 2;
      }
      .pin {
        position: absolute;
        top: 130px;
        left: 50%;
        display: block;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 17px;
        color: #021318;
        background-color: #eeecec;
        text-align: left;
        font-size: 11px;
        margin-left: -50px;
        z-index: 2;
        font-size: 10px;
        #pin {
          position: absolute;
          right: 0;
          top: 0;
          width: 35px;
        }
      }
    }
  }
}

.showCodeTextMode {
  cursor: pointer;
  .showCodeTxt {
    text-decoration: underline;
    padding-left: 3px;
    }
  img {
    position: relative;
    top: 2px;
  }
}

.dashbord {
  max-width: 1000px;
  margin: 0 auto;
  .dashbord-header {
    position: relative;
  }
  .balance {
    border: 1px solid black;
    width: 100px;
    padding: 20px;
    border-radius: 15px;
    position: absolute;
    top: 210px;

    @media only screen and (max-width: 600px) {
      top: 20px;
      left: 50%;
      margin-left: -70px;
      position: relative;
    }
    .balance-title {
      font-size: 16px;
    }
    .balance-value {
      font-size: 18px;
    }
  }

  .operations {
    ul.operations-list {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 100vh;
      overflow-y: scroll;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #f1f1f1;
        .operation-picto {
          width: 50px;
          height: 50px;
          background-color: #f1f1f1;
        }
        .operation-amount {
          width: 100px;
          text-align: right;
        }
        .operation-description {
          flex: 1;
          padding: 0 10px;
          text-align: left;
          .operation-title {
            text-transform: capitalize;
          }
          .operation-details {
            span {
              text-transform: lowercase;
              font-size: 12px;
            }
          }
        }
        .operation-amount {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}

.backLink {
  text-align: left;
  margin: 0;
  padding-left: 10px;
  cursor: pointer;
  img {
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
  span {
    font-size: 16px;
    line-height: 24px;
    position: relative;
    top: -3px;
  }
  
}
</style>
  