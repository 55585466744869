const CE_API_BASE_URL = process.env.VUE_APP_CE_API_URL;

async function fetchWithToken(url, options = {}) {
    const response = await fetch(url, {
        ...options,
        headers: {
            ...(options.headers || {}),
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
}

function getUserId()
{
    const userId = localStorage.getItem('userId');
    if (!userId) {
        throw new Error('No UserID found');
    }
    return userId;
}

export const ceApiService = {
    async sendActivationCode(cardUuid) {
        const url = `${CE_API_BASE_URL}/wv_managed_cards/${cardUuid}/sendActivationCode`;
        const options = {
            method: 'POST',body: JSON.stringify({}),
        };

        return fetchWithToken(url, options);
    },
    async getCurrentUserCardDetails() {
        const url = `${CE_API_BASE_URL}/wv_managed_cards?user.authorisedUserId=${getUserId()}`;

        return fetchWithToken(url);
    },
};