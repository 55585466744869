<template>
  <br /><br /><br />
  <div class="stepUp">
    <h3 class="text-center mb-4">Insérer votre numéro de téléphone</h3>
    <form @submit.prevent="updateUserPhoneNumber">
      <div class="form-group">
        <p><img src="@/assets/stepUp.png" /></p>
        <p>
          <p>Veuillez entrer votre numéro de téléphone au format international (ex: +33 6 12 34 56 78).</p>
        </p>
        <br />
        <input
          v-model="phoneNumber"
          type="text"
          placeholder="Entrez votre numéro de téléphone"
        />
        <br /><br />
        <button type="submit">VALIDER MON NUMÉRO</button>
      </div>
    </form>
  </div>
</template>

<script>
import { weavrApiService } from "@/_services/weavrApiService";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const phoneNumber = ref("");
    const router = useRouter();
    const countryCode = ref("");
    const phoneNum = ref("");
    const updateUserPhoneNumber = async () => {
      try {
        validateAndExtractPhoneNumber(phoneNumber, countryCode, phoneNum);
        const response = await weavrApiService.updateUserPhoneNumber(countryCode.value, phoneNum.value);
        await router.push("/admin/stepUpRegister");
      } catch (error) {
        console.error("Une erreur s'est produite:", error);
      }
    };

    const validateAndExtractPhoneNumber = (phoneNumberRef, countryCodeRef, phoneNumRef) => {
      const frenchCountryCode = '+33';
      const phoneNumberPattern = /^(?:\+(\d{1,3}))?(\d{9,10})$/;

      const match = phoneNumberRef.value.replace(/\s+/g, '').match(phoneNumberPattern);

      if (match) {
        countryCodeRef.value = match[1] ? `+${match[1]}` : frenchCountryCode;
        phoneNumRef.value = match[2];
      } else {
        throw new Error('Invalid phone number format');
      }
    };

    return {
      phoneNumber,
      updateUserPhoneNumber,
    };
  },
};
</script>
<style scoped>
.stepUp {
  max-width: 350px;
  margin: auto;
  border: 1px solid #bababa;
  border-radius: 20px;
  padding: 20px;
}
</style>



